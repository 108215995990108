import React from "react";
import ReactDOM from "react-dom/client";
import "./input.css";
import App from "./App";
import { Context } from "./context/Context";
import "react-circular-progressbar/dist/styles.css";


// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Context>
//     <App />
//   </Context>
// );

const app = (
  <Context>
    <App />
  </Context>
);

// Get the root element from the DOM
const rootElement = document.getElementById("root");

// Create a root and render or hydrate based on whether SSR content exists
if (rootElement.hasChildNodes()) {
  // If the root element has child nodes, use hydrateRoot for SSR hydration
  ReactDOM.hydrateRoot(rootElement, app);
} else {
  // Otherwise, use createRoot for initial render
  const root = ReactDOM.createRoot(rootElement);
  root.render(app);
}
