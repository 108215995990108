import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const DynamicMetaTags = ({dec=''}) => {
  // Get the current URL path
  const location = useLocation();
  const currentPath = location.pathname;
  console.log("currentPath=>",currentPath);


  // Construct the base URL and meta tag content
  const baseUrl = `https://www.kizostudy.com${currentPath}`;
  const pathSegments = currentPath.split("/").filter(Boolean);
  const lastSegment = pathSegments[pathSegments.length - 1] || "home";
  const title = `KizoStudy - ${lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1)}`;
  const description = `Find comprehensive study materials and quizzes for ${lastSegment} at KizoStudy. Join us for a better learning experience!`;
  const keywords = `${lastSegment} , ${dec} KizoStudy, quizzes, study materials`;

  return (
    <Helmet>
      <link rel="canonical" href={baseUrl} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Add more meta tags as needed */}
    </Helmet>
  );
};

export default DynamicMetaTags;
